import { useEffect } from 'react';
import {useNavigate, Routes, Route } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import AuthLogin from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import Signup from './pages/Singup';
import Home from './pages/Home';
import Profile from './pages/Profile';
import AboutUs from './pages/AboutUs';
import PlayEarn from './pages/PlayEarn';
import ReferEarn from './pages/ReferEarn';
import Invites from './pages/Invites';
import Consulting from './pages/Consulting';
import Industries from './pages/Industries';
import Services from './pages/Services';
import ThankYou from './pages/ThankYou';
import ConsultantSchedule from './pages/ConsultantSchedule';
import ContactUs from './pages/ContactUs';
import TaxCalculator from './pages/TaxCalculator';
import DirectTax from './pages/DirectTax';
import IndirectTax from './pages/IndirectTax';
import GetServiceDetails from './pages/GetServiceDetails';
import IndustriesDetails from './pages/IndustriesDetails';
import CategoryDetails from './pages/CategoryDetails';
import ContactThankYou from './pages/ContactThankYou';
import { useLocation } from 'react-router-dom';
import Quiz from './pages/Quiz';
function App() {
  const navigate =useNavigate();  
  let location= useLocation(); 
  const isLoggedIn= !!sessionStorage.getItem('MOB');
  useEffect(() => {
    // Check if not logged in and not already on the login page to prevent loops
    if (!isLoggedIn && location.pathname !== '/Auth-Login' && location.pathname !== '/Signup' && location.pathname !== '/'&& location.pathname !== '/Forgot-Form') {
      navigate("/Auth-Login");
    }
  }, [isLoggedIn, navigate, location.pathname]);

  return (
    <>
    
    <div className={location.pathname==='/Play-Earn'?'main-new-start':location.pathname==='/Consulting'?
      'main-new-start':location.pathname==='/Consultant-Schedule'?'main-new-start'
      :location.pathname==='/Tax-Calculator'?'main-new-start'
      :location.pathname==='/Direct-Tax'?'main-new-start'
      :location.pathname==='/Indirect-Tax'?'main-new-start'
      :location.pathname==='/Services'?'main-new-start'
      :location.pathname==='/Industries'?'main-new-start'
      :'main-start'}>
    {isLoggedIn ?(
        <Routes>
        <Route path="/" element={<LandingPage/>}></Route>   
        <Route path="/Home" element={<Home/>}></Route>   
        <Route path="/About-Us" element={<AboutUs/>}></Route>   
        <Route path="/Profile" element={<Profile/>}></Route>   
        <Route path="/Play-Earn" element={<PlayEarn/>}></Route> 
        <Route path="/Refer-Earn" element={<ReferEarn/>}></Route> 
        <Route path="/Invites" element={<Invites/>}></Route> 
        <Route path="/Services" element={<Services/>}></Route> 
        <Route path="/Industries" element={<Industries/>}></Route> 
        <Route path="/Consulting" element={<Consulting/>}></Route> 
        <Route path="/Consultant-Schedule" element={<ConsultantSchedule/>}></Route> 
        <Route path="/Thank-You" element={<ThankYou/>}></Route> 
        <Route path="/Contact-Us" element={<ContactUs/>}></Route> 
        <Route path="/Tax-Calculator" element={<TaxCalculator/>}></Route> 
        <Route path="/Direct-Tax" element={<DirectTax/>}></Route> 
        <Route path="/Indirect-Tax" element={<IndirectTax/>}></Route> 
        <Route path="/GetServiceDetails/:id" element={<GetServiceDetails/>}></Route> 
        <Route path="/IndustriesDetails/:id" element={<IndustriesDetails/>}></Route> 
        <Route path="/GetCategoryDetails/:id/:categoryId" element={<CategoryDetails/>}></Route> 
        <Route path="/Contact-Thank-You" element={<ContactThankYou/>}></Route> 
        <Route path="/Quiz" element={<Quiz/>}></Route> 
        </Routes>
    ):(
      <Routes>
      <Route path="*" element={<AuthLogin/>} />
      <Route path="/Forgot-Form" element={<ForgotPassword/>} />
      <Route path="/Signup" element={<Signup/>}></Route> 
      <Route path="/" element={<LandingPage/>}></Route>  
      </Routes>
    )}
    </div>
    </>
    
  );
}

export default App;
