import React from "react";
import Header from "./layout/Header";
import {Link} from 'react-router-dom'
import thankyou from '../assets/icon/thankyou.png';
import thankyouicon from '../assets/icon/thankyouicon.png';
function ContactThankYou() {
  return (
    <>
    <div className="flex flex-column flex-content-center mt-5"><img src={thankyou}/></div>
       <div className="flex flex-column mt-3 text-center thankyou-text">
        <span>Thank you!</span>
        <span className="contact-thankyou-text">Tony Stark! &nbsp;<img src={thankyouicon} style={{height:"18px"}}/></span>
       </div>
       <div className="flex flex-column flex-item-center mt-2">
        <span>Our team has already received your </span>
        <span>message and will get back to you  soon.</span>
       </div>
        <div className='flex flex-content-center mt-3 px-3 mb-2'>
        <Link to={'/Home'} className='btn button col-6'>Go Home</Link>
    </div>
    </>
  )
}

export default ContactThankYou