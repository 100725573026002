import React, { useState, useEffect } from 'react';
import Header from './layout/Header';
import axios from 'axios';
import { Url } from '../components/ApiUrl';

function Quiz() {
  // State variables
  const userId=sessionStorage.getItem('ID');
  const [questions, setQuestions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [rightAnswers, setRightAnswers] = useState(0);
  const [countdown, setCountdown] = useState(600);
  const [quizFinished, setQuizFinished] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  // Effect to fetch questions from API
  useEffect(() => {
    async function fetchQuestions() {
      try {
        const http = axios.create({
          baseURL: Url, // Replace with your actual base URL
        });
        const response = await http.get('/Get-Question');
        if (response.data.status === '200') {
          const limitedQuestions = response.data.questions.slice(0, 10);
          setQuestions(limitedQuestions);
        }
      } catch (error) {
        console.error('Error fetching questions:', error);
      }
    }

    fetchQuestions();
  }, []);

  // Effect to handle countdown timer
  useEffect(() => {
    if (countdown > 0 && !quizFinished) {
      const interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else if (countdown === 0) {
      handleSubmit();
    }
  }, [countdown, quizFinished]);

  // Function to handle answer submission
  const handleSubmit = () => {  
    
    if (selectedOption) {
      if (selectedOption === questions[currentIndex]?.right_answer) {       
        setRightAnswers((prevRightAnswers) => prevRightAnswers + 1);
      }
    }
    

    if (currentIndex < questions.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
      setCountdown(600);
      setSelectedOption(null);
    } else {
      setQuizFinished(true);
    }
  };

  // Function to render results
  const renderResults = () => {
   
    let resultMessage;
    let emoji;
    if (rightAnswers === questions.length) {
      resultMessage = (
        <span className="good">Perfect, all answers are correct!</span>
      );
      emoji = (
        <div class="emoji">😊</div>
      );
    } else if (rightAnswers > questions.length / 2) {
      resultMessage = (
        <span className="good">Good, you got {rightAnswers} correct answers!</span>
      );
      emoji = (
        <div class="emoji">😊</div>
      );
    } else if (rightAnswers === questions.length / 2) {
      resultMessage = (
        <span className="good">Fair, you got half of the answers correct.</span>
      );
      emoji = (
        <div class="emoji">😊</div>
      );
    } else {
      resultMessage = (
        <span className="bad">Bad, you got {rightAnswers} correct answers.</span>
      );
      emoji = (
        <div class="emoji">😔</div>
      );
    }
   
    const http = axios.create({
      baseURL: Url, // Replace with your actual base URL
    });
    http.post('/save-answer',{user_id: userId,
      number_of_correct_ans:rightAnswers}).then(response=>{
        if(response.data.status=='200'){
        }
    });
    
    return <div className="results">{emoji} {resultMessage}</div>;
    
    
  };

  if (questions.length === 0) {
    return <div>Loading...</div>;
  }
   
  return (
    <>
      <Header />
      <div className="quiz-app px-3">
        {quizFinished ? (
          renderResults()
        ) : (
          <>
            <div className="flex flex-content-center quiz-info mt-5">
              <div className="count flex">
                Questions Count: <span>{questions.length}</span>
               
              </div>
              <div
                className="countdown"
                style={{
                  fontWeight: countdown < 60 ? 'bold' : 'normal',
                  color: countdown < 60 ? 'red' : 'black',
                }}
              >
                Time:{' '}
                {String(Math.floor(countdown / 60)).padStart(2, '0')}:
                {String(countdown % 60).padStart(2, '0')}
              </div>
            </div>
            <div className="quiz-area">
              <h4>{questions[currentIndex]?.question}</h4>              
              <div className="options-area">
                {questions[currentIndex]?.options.map((option, index) => (
                  <div key={index} className="option">
                    <input
                      name="options"
                      type="radio"
                      id={`option_${index}`}
                      data-option={option}
                      checked={selectedOption === option}
                      onChange={() => setSelectedOption(option)}
                    />
                    <label htmlFor={`option_${index}`}>{option}</label>
                  </div>
                ))}
              </div>
            </div>
            <button className="submit-button" onClick={handleSubmit}>
              Submit Answer
            </button>
            <div className="bullets">
              <div className="spans">
                {questions.map((_, index) => (
                  <span key={index} className={index === currentIndex ? 'on' : ''}></span>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Quiz;
