import React, { useState, useEffect } from 'react'
import Header from './layout/Header'
import user from '../assets/icon/User.png';
import slider1 from '../assets/image/slider1.jpeg';
import slider2 from '../assets/image/slider2.jpeg';
import slider3 from '../assets/image/slider3.jpeg';
import axios from 'axios';
import AllCategory from '../components/getCategory';
import { Url, StoriesUrl, ThumbnailUrl } from '../components/ApiUrl';
function Home() {
  const http = axios.create({
    baseURL: Url
  });
  const [showStories, setStories] = useState(true);

  const handleVideoEnded = () => {
    setStories(true);
  };
  const [storiesVideo, setstoriesVideo] = useState([]);
  const viewStories = (id, userId) => {
    setStories(false);
    http.post('Get-Stories-Video', { id: id, userId: userId }).then(response => {
      setstoriesVideo(response.data.res);
    });

  }
  const [activeId, setActiveId] = useState(null);

  let menuResult = '';
  window.activeMenu = function (id, categoryName) {
    setActiveId(id);
    http.post('/getCategory', { catId: id }).then(response => {
      const menulist = response.data.res;
      menuResult = menulist.map(menu =>
        `<a href="/GetCategoryDetails/${menu.id}/${menu.category_id}"><div class="flex mt-2 space-between">
                  <span><img src="${process.env.PUBLIC_URL}/category/${menu.img}" alt="product" style="height: 100px; border-radius: 10px;" /></span>
                  <span class="px-2" style="font-weight: bold; font-size: 14px; color:black">${menu.title}</span>
              </div></a><hr>`
      ).join('');
      document.getElementById('option-menu-result').innerHTML = menuResult;
    });
  }

  let arrayMenu = '';
  http.get('/getCategoryName').then(response => {
    const menulist = response.data.res;
    arrayMenu = menulist.map(menu =>
      `<div id="${activeId === menu.id ? 'active' : ''}"  onClick="activeMenu(${menu.id}, '${menu.category_name}')">
              <span>${menu.category_name}</span>
          </div>`
    ).join('');
    document.getElementById('option-menu').innerHTML = arrayMenu;
  });
  // http.get('/getAllCategory').then(response => {
  //   const menulist = response.data.res;
  //   menuResult = menulist.map(menu =>
  //     `<a href="/GetCategoryDetails/${menu.id}/${menu.category_id}"><div class="flex mt-2 space-between">
  //             <span><img src="${process.env.PUBLIC_URL}/category/${menu.img}" alt="img" style="height: 100px; border-radius: 10px;" /></span>
  //             <span class="px-2" style="font-weight: bold; font-size: 14px; color:black">${menu.title}</span>
  //         </div></a><hr>`
  //   ).join('');
  //   document.getElementById('option-menu-result').innerHTML = menuResult;
  // });

  const hideVideoDiv = () => {
    setStories(true);
  }
  const renderStories = () => {

    return storiesVideo.map(showVideo => (
      <><video style={{ width: "100%", height: "556px" }} controls={true} autoPlay muted onEnded={handleVideoEnded}>
        <source src={StoriesUrl + '/' + showVideo.video} type="video/mp4" />
      </video></>
    ))
  };

  const StoriesThumbnail = () => {
    const [storiesThumbnail, setstoriesThumbnail] = useState([]);
    useEffect(() => {
      http.get('Get-Stories-Thumbnail').then(response => {
        setstoriesThumbnail(response.data.res);
      });
    }, []);
    return storiesThumbnail.map(resShow => (
      <> <div style={{ padding: "8px" }}><img src={ThumbnailUrl + '/' + resShow.thumbnail} alt="" id="logo" onClick={() => viewStories(resShow.id, resShow.userId)} /></div> </>
    ));
  }
  return (
    <>
      <Header></Header>
      <div className='flex flex-item-center space-around remove-scroll' id="consultant-img" style={{ width: "100%", overflow: "auto" }}>
        {StoriesThumbnail()}

      </div>
      <div className={showStories ? 'show-stories' : 'hide-stories'}>
        <div className='video-section-show'>
          <div className='flex flex-content-center mt-2 px-3 mb-1 space-between'>
            <span><i class="fa-solid fa-xmark" style={{ fontSize: "25px" }} onClick={hideVideoDiv}></i></span>
            <span className='next'>Next</span>
          </div>
          <div className='video-container'>
            {renderStories()}
          </div>
        </div>
      </div>
      <div className="flex flex-column flex-content-center px-3 mt-1">
        <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
          <ol className="carousel-indicators">
            <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
          </ol>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img className="d-block w-100" src={slider1} alt="First slide" style={{ height: "160px" }} />
            </div>
            <div className="carousel-item">
              <img className="d-block w-100" src={slider2} alt="Second slide" />
            </div>
            <div className="carousel-item">
              <img className="d-block w-100" src={slider3} alt="Third slide" />
            </div>
          </div>

        </div>
      </div>

      <div className='flex flex-item-center space-around mt-5 px-3' id="option-menu" style={{ width: "100%", overflow: "auto" }}>
        {arrayMenu}
      </div>
      <div className="flex flex-item-center mt-3 px-3 space-between">
        <span style={{ fontSize: "18px", fontWeight: "bold" }}>Recommendation</span>
        <span style={{ fontSize: "16px", fontWeight: "bold", color: "grey" }}>View All</span>
      </div>
      <div id="option-menu-result" className='mt-3 px-3'>
         <AllCategory></AllCategory>
      </div>
    </>
  )
}

export default Home