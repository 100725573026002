import { Routes, Route, Link, useNavigate,useLocation  } from 'react-router-dom';
import { useState } from 'react';
import { Url } from '../components/ApiUrl';
import axios from 'axios';
import google from '../assets/icon/google.png';
import hide from '../assets/icon/hide.png';
import view from '../assets/icon/view.png';
import Popup from '../components/Popup';
import '../components/Popup.css'
function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
export default function Signup() {
    const query = useQuery();
    const referralCode = query.get('ref');
   
    const navigate = useNavigate();
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [mobile, setMobile] = useState();
    const [gender, setGender] = useState();
    const [bday, setBday] = useState();
    const [marital, setMaritalStatus] = useState();
    const [pincode, setPincode] = useState();
    const [area, setArea] = useState();
    const [city, setCity] = useState();
    const [state, setState] = useState();
    const [password, setPassword] = useState();
    const [conPassword, setConPassword] = useState();
    const [referral, setRefCode] = useState(referralCode);
    const [checkBox, setCheckBox] = useState();
    const [loader, setLoader] = useState('off');
    const [buttonPopup, setButtonPopup] = useState(false);
    const [error, setError] = useState();
    const [showpass, setShowpass] = useState(false);
    const http = axios.create({
        baseURL: Url,
    });    
    const submitForm = (e) => {
        e.preventDefault();
        const errors = {
            name:    "Name field cannot be blank",
            email:   "Email field cannot be blank",
            mobile:  "Mobile field cannot be blank",
            gender:  "Please select gender",
            bday:    "Bday field cannot be blank",
            marital: "Please select merital status",
            pincode: "Pin code field cannot be blank",
            area:    "Are field cannot be blank",
            city:    "City field cannot be blank",
            state:   "State field cannot be blank",
            checkBox: "Please accecpt all terms & Condition",
            conPassword: "Confirm Password field cannot be blank",
            password: "Password field cannot be blank",

        };
        for (let field in errors) {
            if (!eval(field)) {
                setError(errors[field])
                setButtonPopup(true);
                return;
            }
        }
        setLoader('on');
        http.post('/register', { name: name, email: email, mobile: mobile, gender: gender, bday: bday, metarial: marital, pincode: pincode, area: area, city: city, state: state, conPassword: conPassword, referral: referral }).then(response => {
            if (response.data.status == '401') {
                setLoader('off');
            } else if (response.data.status == '200') {
                setLoader('off');
                navigate("/Auth-Login");
            }
        }).catch(error => {

        });

    }
    const getPincode = () => {
        http.post('/getStateCity', { pincode: pincode }).then(response => {
            if (response.data.status == '200') {
                var responseData = response.data.res;
                var areaOption = '<option> Select Type </option>';
                for (let result in responseData) {

                    document.getElementById('state').value = responseData[result].STATE;
                    setState(responseData[result].STATE)
                    document.getElementById('city').value = responseData[result].DISTRICT;
                    setCity(responseData[result].DISTRICT)
                    areaOption += '<option>' + responseData[result].AREA + '</option>';
                }
                document.getElementById('area-inp').innerHTML = areaOption;
            } else if (response.data.status == '400') {
                setError(response.data.message)
                setButtonPopup(true);
            }
        });
    }
    const validateEmail = () => {
        http.post('/validate-email', { email: email }).then(response => {
            if (response.data.status == '400') {
                setError(response.data.message);
                setButtonPopup(true);
                document.getElementById('email').value = '';

            }
        })
    }
    const validateMobile = () => {
        http.post('/validate-mobile', { mobile: mobile }).then(response => {
            if (response.data.status == '400') {
                setError(response.data.message);
                setButtonPopup(true);
                document.getElementById('mobile').value = '';

            }
        })
    }
    const toggleMask = () => {
        setShowpass(true);
    }
    const toggleMask2 = () => {
        setShowpass(false);
    }
    const checkPassword = () =>{ 
        if (password === "" || conPassword === "") {
            setError('Password fields cannot be empty');
            setButtonPopup(true);
            return;
        } else if (password !== conPassword) {
            setError('Retype Password Not Match');
            setButtonPopup(true);
        }
      
    }

    return (
        <>
            <div id="otp-container">
                <div className="otp-inner">
                    <div className="otp-form">
                        <p>Please input the 6 digit code to verify your mobile number</p>
                        <input type="text" id="otp" inputMode="numeric" className="form-control" pattern="[0-9]{6}" placeholder="######" />
                        <button type="button" className="btn mt-1" >Submit</button>
                        <h6 className="mt-2">Edit mobile number <i className="fa fa-edit"></i></h6>
                        <div id="recaptcha"></div>
                    </div>
                </div>
            </div>
            <Popup trigger={buttonPopup} setTrigger={setButtonPopup}>
                <div className="flex flex-content-center mb-4 mt-4">
                    <span id="error-login">{error}</span>
                </div>
            </Popup>
            
            <div id="scroll-remove" className="form-container">
                <div className="flex flex-content-center mt-4"><span id="login-title" className="header-text">Create Your Account</span></div>
                <div className="flex flex-content-center"><span id="login-title-sub" className="header-text">Welcome! Please enter your details</span></div>
                <div className="flex flex-content-center mt-1 px-4 form-control"><span id="login-title-sub" className="header-text"><img src={google} id="fa-icon" /> &nbsp;Sign up with Google</span></div>
                <div className="signup mt-3">
                    <label>Name</label>
                    <div className="input-group">
                        <input type="text" onChange={e => setName(e.target.value)} className="form-control" autoComplete="name" placeholder="Enter your name" required />
                    </div>
                    <label >Email</label> <i className="fa fa-spinner fa-spin" id="check-email"></i>
                    <div className="input-group">
                        <input type="email" onChange={e => setEmail(e.target.value)} className="form-control" onBlur={validateEmail} autoComplete="email" id="email" placeholder="Enter your email" required />
                    </div>

                    <label >Mobile No.</label> <i className="fa fa-spinner fa-spin" id="check-mob"></i>
                    <div className="input-group">
                        <input type="tel" onChange={e => setMobile(e.target.value)} pattern="[6-9]{1}[0-9]{9}" onBlur={validateMobile} className="form-control" autoComplete="tel" id="mobile" placeholder="Enter your mobile no." required minLength={10} maxLength={10} inputMode="numeric" title="Please enter valid mobile number." />
                    </div>
                    <label>Date of Birth</label>
                    <div className="input-group">
                        <input type="date" onChange={e => setBday(e.target.value)} className="form-control" autoComplete="bday" max="<?= date('Y-m-d') ?>" placeholder="Enter your DOB" required />
                    </div>
                    <label>Gender</label>
                    <div className="input-group">
                        <select onChange={e => setGender(e.target.value)} className="form-control" required>
                            <option value="">Select Type</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
                    <label>Marital Status</label>
                    <div className="input-group">
                        <select onChange={e => setMaritalStatus(e.target.value)} className="form-control" required>
                            <option value="">Select Type</option>
                            <option value="Single">Single</option>
                            <option value="Married">Married</option>
                        </select>
                    </div>
                    <div id="dom">
                        <label>Date of Marriage</label>
                        <div className="input-group">
                            <input type="date" name="marriageDate" id="marriageDate" className="form-control" max="<?= date('Y-m-d') ?>" />
                        </div>
                    </div>

                    <label>Pincode of current location</label> <i className="fa fa-spinner fa-spin" id="get_pin"></i>
                    <div className="input-group">
                        <input type="text" onChange={e => setPincode(e.target.value)} className="form-control" autoComplete="postal-code" placeholder="Enter your pincode" required minLength={6} maxLength={6} inputMode="numeric" onBlur={getPincode} />
                    </div>

                    <label>Area</label>
                    <div className="input-group" >
                        <select onChange={e => setArea(e.target.value)} className="form-control" id="area-inp" required>
                            <option disabled selected>Select an area</option>
                        </select>

                    </div>
                    <label>City</label>
                    <div className="input-group">
                        <input type="text" onChange={e => setCity(e.target.value)} className="form-control" autoComplete="city" placeholder="Enter your city" id='city' readOnly />
                    </div>

                    <label>State</label>
                    <div className="input-group">
                        <input type="text" onChange={e => setState(e.target.value)} className="form-control" autoComplete="state" placeholder="Enter your state" id='state' readOnly />
                    </div>

                    <label>Password</label>
                    <div className="input-group">
                        <input type={showpass ? "text" : "password"} onChange={e => setPassword(e.target.value)} className="form-control" autoComplete="new-password"   placeholder="Enter Password" required />
                        <div className="input-group-append">
                            <div className="input-group-text">
                                <a href="#" onClick={showpass ? toggleMask2 : toggleMask} id="eye"><img src={showpass ? view : hide} id="fa-icon" /></a>
                            </div>
                        </div>
                    </div>
                    <label>Retype Password</label>
                    <div className="input-group">
                        <input type={showpass ? "text" : "password"} onChange={e => setConPassword(e.target.value)} className="form-control" autoComplete="password-conf" onBlur={checkPassword} placeholder="Enter Password" required />
                        <div className="input-group-append">
                            <div className="input-group-text">
                                <a href="#" onClick={showpass ? toggleMask2 : toggleMask} id="eye2"><img src={showpass ? view : hide} id="fa-icon" /></a>
                            </div>
                        </div>
                    </div>

                    <label>Referral Code</label>
                    <div className="input-group">
                        <input type="text" onChange={e => setRefCode(e.target.value)} className="form-control" autoComplete="ref_code" placeholder="######" value={referralCode} />
                    </div>
                    <div className="form-group mt-2">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" onChange={e => setCheckBox(e.target.value)} required />
                            <label id="login-title-sub">
                                &nbsp; I accept all terms & conditions
                            </label>
                        </div>
                    </div>
                    <div className="mt-2">
                        {loader == 'off' && <><button className="button col" onClick={submitForm} type="button" value="Login" id="sub-btn">Submit</button></>}
                        {loader == 'on' && <><button disabled className="button col" id="sub-btn"><center>Please wait..</center></button></>}

                    </div>
                    <div className="flex flex-column flex-content-center mt-3 mb-3">
                        <span id="login-title-sub">Already have an account?&nbsp;<Link to={"/Auth-Login"} ><u>Login</u></Link></span>
                    </div>
                </div>
            </div>
        </>
    );
}