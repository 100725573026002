import React,{useState} from 'react';
import Header from './layout/Header';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {Url} from '../components/ApiUrl';
function Services() {
    const http=axios.create({
        baseURL:Url
    });
   
    const [box,setBox]= useState(false); 
    const serviceMenu = [
        { id: 1, name: "Management Function" },
        { id: 2, name: "Corporate Restructuring" },
        { id: 3, name: "Brand Management" },
        { id: 4, name: "Organisation Designing" },
        { id: 5, name: "HR Development" },
        { id: 6, name: "Sales Assurance" },
        { id: 7, name: "Fund Requirement" },
        { id: 8, name: "Customized Business Plan Creation" },
        { id: 9, name: "Corporate Leadership Programs" },
    ];
    const renderServiceBoxes = () => {
      
        return serviceMenu.map(res => (
            <>
               
                <div className="card" id={box===res.id?'active':''}>
                    <div className="card-body">
                        <Link to={'/GetServiceDetails/'+res.id}  style={{color:"black"}}><div class="card-title text-center">{res.name}</div></Link> 
                    </div>
                </div>
                
            </>
        ));
    }
    const handelBox = (id) =>{
        setBox(id);        
    }
    
    return (
        <>
            <Header />
            <hr/>
            <div className="flex flex-content-center mb-2 space-around">                
                <div class="px-2 service-box">
                {renderServiceBoxes()}
                </div>
            </div>
        </>
    )
}

export default Services