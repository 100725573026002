import axios from 'axios';
import { Url } from '../components/ApiUrl';
import banner from '../assets/image/banner.png'; // Assuming banner is an image asset you want to use
function AllCategory(props){
const http = axios.create({
  baseURL: Url
});

let RecordCategory = '';
// Wait for the DOM content to load before executing JavaScript
http.get('/getAllCategory').then(response => {
  const menulist = response.data.res;
  const RecordCategory = menulist.map(menu =>
    `<a href="/GetCategoryDetails/${menu.id}/${menu.category_id}">
      <div class="flex mt-2 space-between">
        <span>
          <img src="${process.env.PUBLIC_URL}/category/${menu.img}" alt="img" style="height: 100px; border-radius: 10px;" />
        </span>
        <span class="px-2" style="font-weight: bold; font-size: 14px; color:black">
          ${menu.title}
        </span>
      </div>
    </a><hr>`
  ).join('');
  document.getElementById('option-menu-result').innerHTML = RecordCategory;
});
   
}
export default AllCategory;
