import React from 'react'
import { Url } from '../components/ApiUrl';
import Header from './layout/Header';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useState } from 'react';
import Popup from '../components/Popup';
function ContactUs() {
    const navigate = useNavigate();
    const [name,setName]=useState();
    const [email,seEmail]=useState();
    const [mobile,setMobile]=useState();
    const [msg,setMsg]=useState();
    const [buttonPopup,setButtonPopup]= useState();
    const [loader,setLoader]=useState('off');
    const [error,setError]=useState();
    const http =axios.create({
        baseURL:Url,
    });
    
    const saveContactForm = () =>{
        const errors= {
            name:"Name field cannot be blank",
            email:"Email field cannot be blank",
            mobile:"Mobile field cannot be blank",
            msg:"Message field cannot be blank",
        };
        for(let fielderror in errors){
            if(!eval(fielderror)){
                setError(errors[fielderror])
                setButtonPopup(true);
                return;  
            }
        }
        setLoader('on');
            http.post('/saveContact',{name:name,email:email,mobile:mobile,msg:msg}).then(response=>{
                 if(response.data.status=='200'){
                    setLoader('off');
                      navigate("/Contact-Thank-You");
                   }
            });
    }
  return (
    <>
    <Header></Header>  
   <Popup trigger={buttonPopup} setTrigger={setButtonPopup}>
   <div className="flex flex-content-center mb-4 mt-4">
        <span id="error-login">{error}</span>
    </div>
   </Popup>
    <div className='flex flex-column flex-item-center mt-4 consultant-text1'>
        <span>Contact Us</span>      
    </div>
    <div className='flex flex-column flex-content-center flex-item-center mt-3'>
        <span className='consultant-text-schedule'>Feel free to message us and we will</span>
        <span className='consultant-text-schedule'>get back to you as soon as we can.</span>
    </div>
    <div class="flex flex-column px-3 mt-4"> 
      <div class="input-group mb-2">                
        <input type="text" className="form-control" onChange={e=>setName(e.target.value)}  placeholder="Name" />      
      </div>
    </div>
    <div class="flex flex-column px-3">
      <div class="input-group mb-2">                
        <input type="email" className="form-control" onChange={e=>seEmail(e.target.value)}  placeholder="Email"/>      
      </div>
    </div>
    <div class="flex flex-column px-3">
      <div class="input-group mb-2">                
        <input type="text" className="form-control" onChange={e=>setMobile(e.target.value)}  placeholder="Mobile Number" maxLength={10} inputMode={{ inputMode: 'numeric' }}/>      
      </div>
    </div>
   
    <div className='flex flex-content-center px-3'>
        <textarea className="contact-textarea" rows="4" onChange={e=>setMsg(e.target.value)} placeholder='Message..'>
            
        </textarea>
    </div>
    <div className='flex flex-content-center mt-3 px-3 mb-2'>
        {loader == 'off' && <> <button className='btn button col-6' onClick={saveContactForm}>Submit</button></>}
        {loader == 'on' &&  <button disabled className="button col" id="sub-btn"><center>Please wait..</center></button>}
       
    </div>
   </>
  
  )
}

export default ContactUs