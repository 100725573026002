import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from './layout/Header';
import ReferIcon from '../assets/icon/refer-icon.png';
import whatsapp from '../assets/icon/whatsapp.png';
import facebook from '../assets/icon/facebook.png';
import linkedin from '../assets/icon/linkedin.webp';
import correcticon from '../assets/icon/share-correct-icon.png';

function ReferEarn() {
  const refCode=sessionStorage.getItem('REFCODE');
  const [referalCode] = useState(refCode);
  const [textToCopy, setTextToCopy] = useState(`https://app.3ea.in/Signup?ref=${referalCode}`);

  const CopyText = () => {
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        alert('Copied to clipboard');
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  useEffect(() => {
    const shareButton = document.getElementById('shareButton');
    if (navigator.share) {
      shareButton.addEventListener('click', async () => {
        try {
          await navigator.share({
            title: 'Invite your friends',
            text: 'Invite your friends and earn rewards!',
            url: textToCopy
          });
          console.log('Link shared successfully');
        } catch (error) {
          console.error('Error sharing link:', error);
        }
      });
    } else {
      shareButton.style.display = 'none';
    }
  }, [textToCopy]);
  const encodedMessage = encodeURIComponent(`Hi! Use ${referalCode} while registering for 3ea app and earn 100 points. Download the app by clicking on this link - https://app.3ea.in/Signup?ref=${referalCode}`);
  return (
    <>
      <Header />
      <div className='flex flex-content-end mt-4 px-4'>
        <Link className='ticket-invite-btn' to={'/Invites'}>Track Invites</Link>
      </div>
      <div className='flex flex-content-center mt-2'>
        <div className='invite-friend-box'>
          <div className='flex flex-content-center mt-3'><img src={ReferIcon} style={{ height: "70px" }} alt="Refer Icon" /></div>
          <div className='flex flex-content-center mt-3 refer-text'><span>INVITE YOUR FRIEND</span></div>
          <div className='flex flex-content-center refer-text'><span> AND EARN 100</span></div>
          <div className='flex flex-content-center mt-3 share-text'><span>Share your link</span></div>
          <div className='flex flex-content-center mt-3 ml-3 mr-3 share-input'>
            <input type="text" onChange={(e) => setTextToCopy(e.target.value)} value={textToCopy} id="referral-text" />
            <button className='copy-btn' onClick={CopyText}>Copy</button>
          </div>
          <div className='flex flex-content-center mt-2'>
            <button className='button' id="shareButton">Invite Your Friends</button>
          </div>
          <div className='flex flex-content-center mt-2'>
            <span style={{ fontSize: "0.8rem", fontWeight: "bold" }}>Or Share</span>
          </div>
          <div className='flex flex-content-center mt-2 share-icon'>
          
            <Link to={`http://wa.me/?text=${encodedMessage}`} target="_blank"><span><img src={whatsapp} alt="WhatsApp" /></span></Link>
           
          </div>
        </div>
      </div>
      <div className='flex flex-content-center mt-3'>
        <div className='invite-friend-box2'>
          <div className='flex p-2 '>You’ll get</div>
          <div className='flex px-2'><img src={correcticon} style={{ height: "13px" }} alt="Correct Icon" /><span className='px-2'>50 when friends register using your code.</span></div>
        </div>
      </div>
      <div className='flex flex-content-center mt-3'>
        <div className='invite-friend-box2'>
          <div className='flex p-2 '>They’ll get</div>
          <div className='flex px-2'><img src={correcticon} style={{ height: "13px" }} alt="Correct Icon" /><span className='px-2'>100 when they register using your code.</span></div>
        </div>
      </div>
      <div className='flex flex-content-center mt-2 mb-2 term-condition'>
        <span>Terms and Conditions</span>
      </div>
    </>
  );
}

export default ReferEarn;
