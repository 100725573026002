import React from 'react';
import Header from './layout/Header';
import pointlogo from '../assets/icon/point-logo.png';
import Tictactoe from '../assets/icon/tictactoe.png';
import tetris from '../assets/icon/tetris.png';
import quizTimeNew from '../assets/icon/quizTimeNew.png';
import bowling from '../assets/icon/bowling.png';
import {Link} from 'react-router-dom';
function PlayEarn() {
    const POINTS = sessionStorage.getItem('POINTS');
  return (
   <> 
    <Header></Header>
   <div className="flex flex-column flex-content-center px-3 mt-5">
        <div className='Earn-point flex flex-content-center space-around'>
            <div className='mb-5 mt-3'><img src={pointlogo} style={{height:"80px"}}/></div>
            <div className='mb-5 mt-6' style={{color:"white",fontSize:"20px"}}>Earned Points<br/>{POINTS}</div>
        </div>        
    </div>   
    <div className="flex flex-content-center mt-2  space-around">
        <div className='Play-point'>
            <div className='float-right mb-1 mt-1 px-2'>
             <button className='play-btn'>Play</button>
            </div>
            <div className='text-center'>
                <img src={Tictactoe}/>
            </div>
            <div className='text-center play-text mt-2'>Play Tictactoe</div>
            
        </div>     
        <div className='Play-point'>
            <div className='float-right mb-1 mt-1 px-2'>
             <button className='play-btn'>Play</button>
            </div>
            <div className='text-center'>
                <img src={tetris}/>
            </div>
            <div className='text-center play-text mt-2'>Play Tetris</div>
            
        </div>      
    </div>
    <div className="flex flex-content-center mt-2 space-around">
    <div className='Play-point'>
            <div className='float-right mb-1 mt-1 px-2'>
             <button className='play-btn'>Play</button>
            </div>
            <div className='text-center'>
                <img src={bowling}/>
            </div>
            <div className='text-center play-text mt-2'>Play Tictactoe</div>
            
        </div>      
        <div className='Play-point'>
            <div className='float-right mb-1 mt-1 px-2'>
             <Link to={'/Quiz'} className='play-btn'>Play</Link>
            </div>
            <div className='text-center'>
                <img src={quizTimeNew}/>
            </div>
            <div className='text-center play-text mt-2'>Play Quiz</div>
            
        </div>      
    </div>
   </>
  )
}

export default PlayEarn