import React from 'react';
import Header from './layout/Header';
import aboutimg from '../assets/About/About.png';
function AboutUs() {
    
    return (
        <>
            <Header></Header>
            <div className='flex flex-content-center  mt-1 px-2 aboutus'>
                <span>ABOUT</span>
            </div>
            <div className='flex flex-content-center px-2 aboutus'>
                <span>US</span>
            </div>
            <div className='flex flex-column flex-content-center px-2 mt-3 aboutus01'>
                <span className='text-center'>You Aspire to Grow,</span>
                <span className='text-center'>We are a <span style={{ color: "#9ACD32" }} id="innerAbout">‘Growth Catalyst’.</span></span>
                <span className='text-center'>Simple.</span>
                <span className='text-center'>Yet, Revolutionary.</span>
            </div>
            <div className="flex flex-column flex-content-center px-3 mt-5">
                <img className="d-block w-100" src={aboutimg} alt="First slide" style={{ height: "160px" }} />
            </div>
            <div className="flex flex-column flex-content-center px-3 mt-5 about-content">
               <span className='text-justify'>3EA, a Multi-National Management Consulting Group, consists of a Chartered Accounting firm, a Private Limited Company in Singapore, a TVC production house, and a public limited company in India. It operates in South East Asia and India, catering to the strategic management consulting requirements of start-ups, SMEs, and MNCs.</span>               
            </div>
             <div className="flex flex-column flex-content-center px-3 mt-2 mb-3 about-content">
             <span className='text-justify'>The Management Consulting Services offered by 3EA encompass a broad spectrum of business disciplines designed to provide the expertise that assures the success of an organization. Our delivery of management consulting services is based upon the timely execution of deliverables and performance-oriented results.</span>
            </div>
        </>
    )
}

export default AboutUs