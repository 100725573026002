import React from 'react'
import Header from './layout/Header';
import { Link } from 'react-router-dom';
import next from '../assets/icon/next2.png';
function IndirectTax() {
  return (
    <>
    <Header></Header>
    <div className='flex flex-column flex-item-center mt-4 tax-text'>
        <span>Direct Taxes</span>
    </div>
    <div className='flex flex-column flex-content-center flex-item-center mt-3'>
        <span className='tax-calculator-text'>Indirect taxes are unseen but felt,</span>
        <span className='tax-calculator-text'>they silently support the nation’s</span>
        <span className='tax-calculator-text'>growth by sharing the burden across</span>
        <span className='tax-calculator-text'>consumption.</span>
    </div>
    <div className='flex flex-content-center mt-4'>
        <Link to={"/Direct-Tax"} className='tax-btn'>Sales Tax<span className='float-right' ><img src={next} style={{ height: "20px" }} /></span></Link>
    </div>
    <div className='flex flex-content-center mt-4'>
        <Link to={"/Direct-Tax"} className='tax-btn'>Service Tax<span className='float-right' ><img src={next} style={{ height: "20px" }} /></span></Link>
    </div>
    <div className='flex flex-content-center mt-4'>
        <Link to={"/Direct-Tax"} className='tax-btn'>Custom Duty<span className='float-right' ><img src={next} style={{ height: "20px" }} /></span></Link>
    </div>
    <div className='flex flex-content-center mt-4'>
        <Link to={"/Direct-Tax"} className='tax-btn'>GST<span className='float-right' ><img src={next} style={{ height: "20px" }} /></span></Link>
    </div>
    <div className='flex flex-content-center mt-4'>
        <Link to={"/Direct-Tax"} className='tax-btn'>VAT<span className='float-right' ><img src={next} style={{ height: "20px" }} /></span></Link>
    </div>
    </>
  )
}

export default IndirectTax