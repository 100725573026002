
import '../assets/css/style.css';
import '../assets/css/flex.css';
import '../assets/css/common.css';
import { Routes,Route,Link } from 'react-router-dom';
// import logo from '../assets/logo/3ea-logo.png';
import logo from '../assets/logo/newLogo.png';
import mascot from '../assets/logo/Mascot2a.png';
export default function LandingPage(){
    return(
        <>
      <section className="main-start">
        <div className='top-section'>
          <div className="top-section-inner">
            <div className="flex flex-column flex-item-center">
              <div className="flex flex-column flex-item-center mt-3">
                <img src={logo} alt="" id="logo" style={{width:"113px"}}/>
              </div>
              <div className="flex flex-column flex-item-center mt-4">

                <span id="login-title">Your journey, our support.</span>
                <span id="login-title">Let's unlock the path to</span>
                <span id="login-title">success together.</span>
              </div>
              <div className="flex flex-column flex-content-end" style={{width:"2.5%"}}>
                <img src={mascot} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="buttom-section">
          <div className="buttom-section-inner">
            <div className="flex flex-column flex-item-center mt-6">
              <div className="flex flex-column flex-content-center">

                <button className="button mt-2 px-5"><Link to={"/Auth-Login"}>Login</Link></button>
                <button className="button mt-2 px-5"><Link to={"/Signup"}>Signup</Link></button>               
              </div>
              <div className="flex flex-column flex-content-center mt-6">
                <span id="login-title" className="text-center">Knowledge is the New </span>
                <span id="login-title" className="text-center">Currency</span>                
              </div>
            </div>
          </div>
        </div>
      </section>
        </>
    );
}