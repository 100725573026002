import React from 'react'
import {Link} from 'react-router-dom';
import Header from './layout/Header';
import ConsultantImg from '../assets/image/consultant.png';
import next from '../assets/icon/next2.png';
function Consulting() {
  return (
    <>
    <Header></Header>
    <div className='flex flex-content-center mt-5'>
        <span className='consultant-text'>100+  Successful Stories </span>
    </div>
    <div className='flex flex-column flex-item-center mt-4 consultant-text1'>
        <span>Effective</span>
        <span>innovative solutions</span>
        <span>for business</span>
    </div>
    <div className='flex flex-content-center mt-4'>
        <Link to={"/Consultant-Schedule"} className='consult-btn'>Get a consult<span className='float-right' ><img src={next} style={{height: "20px"}} /></span></Link>
    </div>
    <div className='flex flex-content-center mt-4'>
        <div><img src={ConsultantImg} style={{height: "280px"}}/></div>
    </div>
    </>
  )
}

export default Consulting