import React from 'react'
import {Link} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
function LeftMenu(props) {
  const navigate =useNavigate();
  const Logout = () =>{
  sessionStorage.clear();  
  navigate("/Auth-Login");
 }
  return (props.trigger)?(
    <div id="mySidenav" className="sidenav">
    <Link  className="closebtn" onClick={()=>props.setTrigger(false)}>&times;</Link>
    <Link to={"/Home"}><i className="fa-solid fa-house" id="icon"></i> &nbsp; Home</Link>
    <Link to={"/About-Us"}><i className="fa-regular fa-address-card" id="icon"></i>&nbsp; About Us</Link>
    <Link to={"/Contact-Us"}><i className="fa-solid fa-phone" id="icon"></i>&nbsp; Contact Us</Link>
    <Link to={"/Consulting"}><i className="fa-solid fa-phone" id="icon"></i>&nbsp; Consulting</Link>
    <Link to={"https://3ea.in/case-study/"} target='_blank'><i className="fa-solid fa-file" id="icon"></i>&nbsp; Case Studies</Link>
    <Link to={"/Industries"}><i className="fa-solid fa-industry" id="icon"></i>&nbsp; Industries</Link>
    <Link to={"/Services"}><i className="fa-solid fa-gears" id="icon"></i>&nbsp; Services</Link>
    <Link to={"https://3ea.in/#media-coverage"} target='_blank'><i className="fa-solid fa-newspaper" id="icon"></i>&nbsp; Press Release</Link>
    <Link to={"/Tax-Calculator"}><i className="fa-solid fa-newspaper" id="icon"></i>&nbsp; Tax Calculator</Link>
    <Link to={"https://3ea.in/blog/"}><i className="fa-solid fa-blog" id="icon"></i>&nbsp; Blog</Link>
    <Link to={"/Refer-Earn"}><i className="fa-solid fa-share" id="icon"></i>&nbsp; Refer & Earn</Link>
    <Link to={"/Play-Earn"}><i className="fa-solid fa-gamepad" id="icon"></i>&nbsp; Play & Earn</Link>
    <Link to={"/Profile"}><i className="fa-solid fa-user" id="icon"></i>&nbsp; Profile</Link>
    <Link onClick={Logout}><i className="fa-solid fa-right-from-bracket" id="icon"></i>&nbsp; Logout</Link>
    </div>
  ):"";
}

export default LeftMenu