import React from 'react'
import { Routes,Route,Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';
import logo from '../assets/logo/3ea-logo.png';
import mail from '../assets/icon/mail.png';
import otpimg from '../assets/icon/otp.png';
import padlock from '../assets/icon/padlock.png';
import { Url } from '../components/ApiUrl';
import Popup from '../components/Popup';
import '../components/Popup.css'
function ForgotPassword() {
    const http=axios.create({
      baseURL:Url
    });
    const [email,setEmail] =useState();
    const navigate = useNavigate();
    const [otp,setOtp] =useState();
    const [newPass,setNewPassword] =useState();
    const [confirmPass,setConfirmPassword] =useState();
    const [loader,setLoader] = useState('off');
    const [buttonPopup,setButtonPopup] = useState(false);
    const [error,setError]= useState();
    const [otpbox,setotpbox]=useState(false);
    const [emailbox,setemailbox]=useState(true);
    const [resetpassword,setresetpasswordbox]=useState(false);
    const SendOtp= ()=>{
          setLoader('on');
          http.post('Send-Otp',{email:email}).then(response=>{
               if(response.data.status=='200'){
                setError(response.data.message)
                setButtonPopup(true);
                setLoader('off');
                setotpbox(true);
                setemailbox(false);
               }
               if(response.data.status=='400'){
                setError(response.data.message)
                setButtonPopup(true);
                setLoader('off');
                document.getElementById('email').value='';
               }
          });
    }
    const VerifyOtp= ()=>{
          setLoader('on');
          http.post('Verify-Otp',{otp:otp}).then(response=>{
               if(response.data.status=='200'){
                setError(response.data.message)
                setButtonPopup(true);
                setLoader('off');
                setresetpasswordbox(true);
                setotpbox(false)
               }
               if(response.data.status=='400'){
                setError(response.data.message)
                setButtonPopup(true);
                setLoader('off');
                document.getElementById('otp').value='';
               }
          });
    }
    const ResetPassword= ()=>{
      setLoader('on');
      if(newPass!==confirmPass){
        setError('Confirm Password Not Match');
        setButtonPopup(true);
        setLoader('off');
      }else{
          setLoader('on');
          http.post('Reset-Password',{confirmPass:confirmPass,email:email}).then(response=>{
               if(response.data.status=='200'){
                setError(response.data.message)
                setButtonPopup(true);
                setLoader('off');
                setTimeout(function() {
                  navigate("/Auth-Login");
                }, 2000);
                
               }
               if(response.data.status=='400'){
                setError(response.data.message)
                setButtonPopup(true);
                setLoader('off');
             
               }
          });
        }
    }
  return (
    <>
       <Popup trigger={buttonPopup} setTrigger={setButtonPopup}>
          <div className="flex flex-content-center mb-4 mt-4">
          <span id="error-login">{error}</span>
          </div>
        </Popup>
        
        <div className='login-top-section'>
          <div className="top-section-inner">
            <div class="flex flex-column flex-item-center mt-5">
              <div className="flex flex-column flex-item-center mt-4">
                <img src={logo} alt=""  id="logo" />
              </div>
              <div className="flex flex-column flex-item-center mt-4">
                <span id="login-title">Forgot Password</span>                
              </div>              
            </div>
          </div>
        </div>
        <div className="buttom-section">
          <div className="px-5">
            <div id={emailbox?'':'email-box'}>         
              <div className="input-group mb-2">
                  <div className="input-group-prepend">
                      <div className="input-group-text"><img src={mail} id="fa-icon"/></div>
                  </div>
                  <input type="text" className="form-control" onChange={e=>setEmail(e.target.value)}  autoComplete="email" placeholder="Enter Your Email Id" id="email" />
              </div>          
            
              <div className="mt-2">
                {loader=='off'&& <><button className="button col" type="button" onClick={SendOtp}>Sent OTP</button></>}
                {loader=='on'&& <><button className="button col">Please wait..</button></>}
                  
              </div>
            </div> 
            <div id={otpbox?'':'otp-box-show'}>         
              <div className="input-group mb-2">
                  <div className="input-group-prepend">
                      <div className="input-group-text"><img src={otpimg} id="fa-icon"/></div>
                  </div>
                  <input type="number" className="form-control" onChange={e=>setOtp(e.target.value)}  autoComplete="otp" placeholder="Enter 6 digit Number" id="otp" />
              </div>          
            
              <div className="mt-2">
                {loader=='off'&& <><button className="button col" type="button" onClick={VerifyOtp}>Verify OTP</button></>}
                {loader=='on'&& <><button className="button col">Please wait..</button></>}
                  
              </div>
            </div> 
            <div id={resetpassword?'':'password-box-show'}>         
              <div className="input-group mb-2">
                  <div className="input-group-prepend">
                      <div className="input-group-text"><img src={padlock} id="fa-icon"/></div>
                  </div>
                  <input type="password" className="form-control" onChange={e=>setNewPassword(e.target.value)}  autoComplete="password" placeholder="Enter New Password" />
              </div> 
              <div className="input-group mb-2">
                  <div className="input-group-prepend">
                      <div className="input-group-text"><img src={padlock} id="fa-icon"/></div>
                  </div>
                  <input type="password" className="form-control" onChange={e=>setConfirmPassword(e.target.value)}  autoComplete="password" placeholder="Enter Confirm Password" />
              </div>          
            
              <div className="mt-2">
                {loader=='off'&& <><button className="button col" type="button" onClick={ResetPassword}>Update Password</button></>}
                {loader=='on'&& <><button className="button col">Please wait..</button></>}
                  
              </div>
            </div>
            <div className="flex flex-column flex-content-center mt-4">
                <span id="login-title-sub">Not forgotten your password?  <Link to={"/Auth-Login"}><u>Back to login</u></Link></span>
            </div>           
          </div>
        </div>
    </>
  )
}

export default ForgotPassword