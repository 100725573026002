import React from 'react'
import Header from './layout/Header';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {Url} from '../components/ApiUrl';
function GetServiceDetails() {
    const { id } = useParams()
    const http=axios.create({
        baseURL:Url
    });
    
    http.post('/getService',{serviceid:id}).then(response=>{
        const result= response.data.res;
        result.map(showResult=>{
            document.getElementById('show-res').innerHTML=showResult.text;
            document.getElementById('res-title').innerHTML=showResult.serviceName;
        })
    });
  return (
    <>
    <Header></Header>
    <hr/>
    <div className='flex flex-column flex-content-center mt-3'>
        <div className='flex flex-content-center text-center'>
        <h2 id="res-title" className='flex text-bold'></h2>
        </div>
        <div id="show-res"></div>
    </div>
    </>
  )
}

export default GetServiceDetails