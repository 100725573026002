import React,{useState,useRef} from 'react';
import Header from './layout/Header';
import User from '../assets/icon/User.png';
import axios from 'axios';
import Popup from '../components/Popup';
import focus from '../assets/icon/focus.png';
import { Url,ProfileUrl } from '../components/ApiUrl';
import { useNavigate,useLocation } from 'react-router-dom';
function Profile() {     
  const fileInputRef = useRef(null);
  const [preview,setPreview]= useState(null);
  const navigate = useNavigate();
  let location= useLocation();
  const [activeId,setActiveId]= useState(null);
  const [name,setName]=useState();
  const [email,setEmail]=useState();
  const [mobile,setMobile]=useState();
  const [dob,setBday]=useState();
  const [pincode, setPincode] = useState();
  const [gender, setGender] = useState();
  const [area, setArea] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [password,setPassword]=useState();
  const [confirmpass,setConfirmPass]=useState();
  const [buttonPopup,setButtonPopup]= useState();
  const [loader,setLoader]=useState('off');
  const [error,setError]=useState();
  const [showBox,setBox]= useState(null);
  const [userprofile,setProfile]=useState(0);
  const http=axios.create({
      baseURL:Url
  });
  const userId=sessionStorage.getItem('ID');
  const ActiveTab = (id) =>{
    setActiveId(id);
    setBox(id)
  http.post('/get-Record',{id:userId}).then(response=>{   
    if(response.data.status=='200'){
      const getRecord= response.data.res;
      const Record = getRecord.map(showRecord=>(        
        `<p><strong>Name:</strong> ${showRecord.NAME}</p>
        <p><strong>Email Address:</strong> ${showRecord.EMAIL}</p>
        <p><strong>Contact:</strong> +91 ${showRecord.MOB}</p>
        <p><strong>Date of Birth:</strong> ${showRecord.DOB}</p>
        <p><strong>Gender:</strong> ${showRecord.GENDER}</p>
        <p><strong>City:</strong> ${showRecord.CITY}</p>
        <p><strong>Area:</strong> ${showRecord.AREA}</p>
        <p><strong>State:</strong> ${showRecord.STATE}</p>`
        
      ));
      document.getElementById('profile-info').innerHTML=Record.join('');      
    }
        if(response.data.status=='200'){
        const getRecord= response.data.res;
        const Record1 = getRecord.map(showRecord1=>{
         
                setName(showRecord1.NAME);
                setEmail(showRecord1.EMAIL);
                setMobile(showRecord1.MOB);
                setBday(showRecord1.DOB);
                setPincode(showRecord1.PIN);
                setArea(showRecord1.AREA);
                setCity(showRecord1.CITY);       
                setState(showRecord1.STATE);       
                setGender(showRecord1.GENDER);  
                setProfile(showRecord1.USER_PROFILE); 
                  
      });
    }
  });
  }
  const profileList=[
        {id:0,name:"Profile Info"},{id:1,name:"Update Profile"},{id:2,name:"Change Password"}
  ];
  const showList = profileList.map(menu=>
    <><span className={activeId===menu.id?'active':''}  onClick={()=>ActiveTab(menu.id)}>{menu.name}</span></>
    
  ) 
  
  const UpdateProfile = () =>{
    setLoader('on');
    http.post('/update-profile', { name: name,id:userId,email: email, mobile: mobile, gender: gender, bday: dob,pincode: pincode, area: area, city: city, state: state}).then(response => {
     if (response.data.status == '200') {
          setLoader('off');
          setError(response.data.msg)
          setButtonPopup(true);
          
      }
  })
  }
  const UpdatePassword = () =>{
    const error = {
        password: "Enter password",
        confirmpass: "Enter confirm password",
     };
     for(let field in error){
      if(!eval(field)){
        setError(error[field])
        setButtonPopup(true);
        return;
      }
     }
    setLoader('on');
    http.post('/Change-Password',{confirmpass:confirmpass,id:userId}).then(response=>{
        if(response.data.status=='200'){
          setLoader('off'); 
          setError(response.data.msg) 
          setButtonPopup(true);
          sessionStorage.clear();         
          navigate("/Auth-Login");
        }
    });
  }
  const getPincode = (pincode) => {   
    http.post('/getStateCity', { pincode: pincode }).then(response => {
        if (response.data.status == '200') {
            var responseData = response.data.res;
            var areaOption = '<option> Select Type </option>';
            for (let result in responseData) {

                document.getElementById('state').value = responseData[result].STATE;
                setState(responseData[result].STATE)
                document.getElementById('city').value = responseData[result].DISTRICT;
                setCity(responseData[result].DISTRICT)
                areaOption += '<option>' + responseData[result].AREA + '</option>';
            }
            document.getElementById('area-inp').innerHTML = areaOption;
        } else if (response.data.status == '400') {
            setError(response.data.message)
            setButtonPopup(true);
        }
    });
}
const showProfileBox = () => {
  fileInputRef.current.click();
  fileInputRef.current.onchange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileURL = URL.createObjectURL(file);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('id', userId); // Append other necessary data

      http.post('/Upload-Profile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        if (response.data.status === '200') {
          setError(response.data.msg);
          setButtonPopup(true);
        }
      }).catch(error => {
        console.error('There was an error uploading the file!', error);
      });

      setPreview(fileURL);
    }
  };
};

let Record = '';

http.post('/get-Record', { id: userId })
  .then(response => {
    if (response.data.status === '200') {
      const getRecord = response.data.res;
      Record = getRecord.map(showRecord => (        
        `<p><strong>Name:</strong> ${showRecord.NAME}</p>
         <p><strong>Email Address:</strong> ${showRecord.EMAIL}</p>
         <p><strong>Contact:</strong> +91 ${showRecord.MOB}</p>
         <p><strong>Date of Birth:</strong> ${showRecord.DOB}</p>
         <p><strong>Gender:</strong> ${showRecord.GENDER}</p>
         <p><strong>City:</strong> ${showRecord.CITY}</p>
         <p><strong>Area:</strong> ${showRecord.AREA}</p>
         <p><strong>State:</strong> ${showRecord.STATE}</p>`
      )).join('');
      document.getElementById('profile-info-old').innerHTML = Record;
    }
    if(response.data.status=='200'){
      const getRecord= response.data.res;
      const Record1 = getRecord.map(showRecord1=>{ 
              setProfile(showRecord1.USER_PROFILE); 
                
    });
  }
  })
  .catch(error => {
    console.error('There was an error fetching the record!', error);
  });

  return (
    <>
    <Header></Header>
    <Popup trigger={buttonPopup} setTrigger={setButtonPopup}>
   <div className="flex flex-content-center mb-4 mt-4">
        <span id="error-login">{error}</span>
    </div>
   </Popup>
    <hr/>

    <div className='flex flex-content-center space-around'>     
          {userprofile == 0 ? (<img src={preview !== null ? preview : User} className='logo-profile'/>):
          (<img src={ProfileUrl+'/'+ userprofile} className='logo-profile'/>)}      
         
         <img src={focus} id="camera" onClick={showProfileBox}/>
         <input type='file' ref={fileInputRef} style={{display: 'none'}} />
        <div className='flex flex-column text-center'>
        <span className="profile-text">Gufran Ahmad</span>
        <span style={{fontSize:"17px"}}><i class="fa-regular fa-envelope"></i> {sessionStorage.getItem('EMAIL')}</span>
        <span style={{fontSize:"17px"}}><i class="fa-solid fa-phone"></i> 91+ {sessionStorage.getItem('MOB')}</span>
        </div>
        <div></div>
    </div>
    <div className="border-bottom flex flex-content-center mt-4 space-around tab">
     {showList}
   </div>  
   <div className="profile-details" id={showBox==0?'details-profile':''}>
      <div className="card-body2">           
          <div id="profile-info">
      
          </div>
      </div>
  </div>
  <div id={showBox==0 || showBox==1 || showBox==2?'hide-profile':'profile-details-old'}>
      <div className="card-body2">           
          <div id="profile-info-old">
      
          </div>
      </div>
  </div>
  <div className='update-form px-3' id={showBox==1?'update-profile':''}>
     <div class="flex flex-column px-3 mt-4"> 
     <label>Name</label>
        <div class="input-group mb-2">                
          <input type="text" className="form-control" value={name} onChange={e => setName(e.target.value)} placeholder="Enter Name" id="name" />      
        </div>
      </div>
      <div class="flex flex-column px-3">
      <label>Email</label>
        <div class="input-group mb-2">                
          <input type="email" className="form-control" value={email} onChange={e => setEmail(e.target.value)} placeholder="Enter Email" id='email'/>      
        </div>
      </div>
      <div class="flex flex-column px-3">
      <label>Mobile Number</label>
        <div class="input-group mb-2">                
          <input type="text" className="form-control" value={mobile} onChange={e => setMobile(e.target.value)} placeholder="Mobile Number" maxLength={10} inputMode={{ inputMode: 'numeric' }} id="mobile"/>      
        </div>
      </div> 
      <div class="flex flex-column px-3">
      <label>DOB</label>
        <div class="input-group mb-2">                
          <input type="date" className="form-control" value={dob} onChange={e => setBday(e.target.value)}  placeholder="Enter DOB" id="dob"/>      
        </div>
      </div>
      <div class="flex flex-column px-3">
      <label>Gender</label>
      <div className="input-group">
          <select onChange={e => setGender(e.target.value)} className="form-control">
              <option value="">Select Type</option>
              <option value="Male" selected={gender==='Male'}>Male</option>
              <option value="Female" selected={gender==='Female'}>Female</option>
              <option value="Other" selected={gender==='Other'}>Other</option>
          </select>
      </div>
      </div>
      <div class="flex flex-column px-3">
      <label>Pincode of current location</label>
        <div class="input-group mb-2">                
          <input type="number" className="form-control" value={pincode} onChange={e => setPincode(e.target.value)}  onBlur={(event) => getPincode(event.target.value)} placeholder="Enter Pin Code" maxLength={6} inputMode={{ inputMode: 'numeric' }} id="pincode"/>      
        </div>
      </div>
      <div class="flex flex-column px-3">
      <label>Area</label>
      <div className="input-group" >
          <select onChange={e => setArea(e.target.value)} className="form-control" id="area-inp">
              <option disabled selected>{area}</option>
          </select>
      </div>
      </div>
      <div class="flex flex-column px-3">
      <label>City</label>
      <div className="input-group">
          <input type="text" className="form-control" value={city} onChange={e => setCity(e.target.value)} autoComplete="city" placeholder="Enter your city" id='city' />
      </div>
      </div>
      <div class="flex flex-column px-3">
      <label>State</label>
      <div className="input-group">
          <input type="text" className="form-control" value={state} onChange={e => setState(e.target.value)} autoComplete="state" placeholder="Enter your state" id='state' />
      </div>
      </div>      
      <div className='flex flex-content-center mt-3 px-3 mb-2'>
          {loader == 'off' && <> <button className='btn button col-6' onClick={UpdateProfile}>Update</button></>}
          {loader == 'on' &&  <button disabled className="button col" id="sub-btn"><center>Please wait..</center></button>}        
      </div>
  </div>
  <div className='update-password-form px-3' id={showBox==2?'update-password':''}>
    <div class="flex flex-column px-3 mt-4"> 
        <div class="input-group mb-2">                
          <input type="text" className="form-control" onChange={e=>setPassword(e.target.value)}  placeholder="Enter Password" />      
        </div>
      </div>
      <div class="flex flex-column px-3">
        <div class="input-group mb-2">                
          <input type="text" className="form-control" onChange={e=>setConfirmPass(e.target.value)}  placeholder="Enter Confirm Password"/>      
        </div>
      </div>
       
      <div className='flex flex-content-center mt-3 px-3 mb-2'>
          {loader == 'off' && <> <button className='btn button col-6' onClick={UpdatePassword}>Save & Change</button></>}
          {loader == 'on' &&  <button disabled className="btn button col-6"><center>Please wait..</center></button>}
        
      </div>
  </div>
    </>
  )
}

export default Profile