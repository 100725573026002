import React from 'react'
function Popup(props) {
  return (props.trigger)?(
    <div className='popup'>
       <div className='popup-inner'>
            <button id='colse-btn' onClick={()=>props.setTrigger(false)}><i class="fa-solid fa-xmark"></i></button>
            { props.children }
       </div>
    </div>
  ):"";
}

export default Popup
