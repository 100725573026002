import React,{useEffect,useState} from 'react';
import Header from './layout/Header';
import { Url } from '../components/ApiUrl';
import { Link } from 'react-router-dom';
import axios from 'axios';

function Industries() {
     const http=axios.create({
            baseURL:Url
     });
    const [Industries,setIndustries]= useState([]);

    useEffect(()=>{
      const fetchIndustries = () =>{
        http.get('/GetIndustries').then(response=>{
            const result= response.data.res;
            setIndustries(result);
    });
      }
        fetchIndustries();
    },[]);
   
        const renderIndustriesBox = () =>{
            return Industries.map(res=>(
                <>
                 <div className="card">
                    <div className="card-body">
                        <Link to={'/IndustriesDetails/'+res.id}  style={{color:"black"}}><div class="card-title text-center">{res.industries_name}</div></Link> 
                    </div>
                </div>
                </>
            ));
        }
  return (
    <>
    <Header />
    <hr/>
    <div className="flex flex-content-center mb-2 space-around">                
        <div class="px-2 service-box">
        {renderIndustriesBox()}
        </div>       
    </div>
    </>
  )
}

export default Industries