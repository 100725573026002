import React from 'react'
import Header from './layout/Header';
import { Link } from 'react-router-dom';
import next from '../assets/icon/next2.png';
function DirectTax() {
  return (
    <>
    <Header></Header>
    <div className='flex flex-column flex-item-center mt-4 tax-text'>
        <span>Direct Taxes</span>
    </div>
    <div className='flex flex-column flex-content-center flex-item-center mt-3'>
        <span className='tax-calculator-text'>Direct taxes are the backbone of a nation’s</span>
        <span className='tax-calculator-text'>fiscal health, reflecting the citizenry’s</span>
        <span className='tax-calculator-text'>contribution to national progress.</span>
    </div>
    <div className='flex flex-content-center mt-4'>
        <Link to={"/Direct-Tax"} className='tax-btn'>Income Tax<span className='float-right' ><img src={next} style={{ height: "20px" }} /></span></Link>
    </div>
    <div className='flex flex-content-center mt-4'>
        <Link to={"/Direct-Tax"} className='tax-btn'>Capital Gain Tax<span className='float-right' ><img src={next} style={{ height: "20px" }} /></span></Link>
    </div>
    <div className='flex flex-content-center mt-4'>
        <Link to={"/Direct-Tax"} className='tax-btn'>Securities Tax<span className='float-right' ><img src={next} style={{ height: "20px" }} /></span></Link>
    </div>
    <div className='flex flex-content-center mt-4'>
        <Link to={"/Direct-Tax"} className='tax-btn'>Perquisite Tax<span className='float-right' ><img src={next} style={{ height: "20px" }} /></span></Link>
    </div>
    <div className='flex flex-content-center mt-4'>
        <Link to={"/Direct-Tax"} className='tax-btn'>Corporate Tax<span className='float-right' ><img src={next} style={{ height: "20px" }} /></span></Link>
    </div>
    </>
  )
}

export default DirectTax