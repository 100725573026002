import React,{useState} from 'react'
import Header from './layout/Header';
import axios from 'axios';
import { useParams,Link } from 'react-router-dom';
import {Url} from '../components/ApiUrl';
function IndustriesDetails() {
    const { id } = useParams()
    const [catUrl,setCatUrl]= useState();
    const http=axios.create({
        baseURL:Url
    });
    http.post('/getIndustriesDetails',{IndId:id}).then(response=>{       
        const result= response.data.res;
        result.map(showResult=>{
            document.getElementById('show-res').innerHTML=showResult.text;
            document.getElementById('res-title').innerHTML=showResult.title;
            setCatUrl(showResult.url);
        })
    });
  return (
    <>
    <Header></Header>
    <hr/>
    <div className='flex flex-column flex-content-center mt-3'>
        <div className='flex flex-content-center text-center'>
        <h2 id="res-title" className='flex text-bold'></h2>
        </div>
        <div id="show-res" className='mt-2 px-3 text-justify'></div>
    </div> 
    <div className='flex flex-content-center mb-2 mt-2'>
          <Link to={catUrl} target="_blank"><button className='button'>Read More</button></Link>
    </div>   
    </>
  )
}

export default IndustriesDetails