import React from "react";
import Header from "./layout/Header";
import {Link} from 'react-router-dom'
import thankyou from '../assets/icon/thankyou.png';
function ThanYou(){
    return(
        <>
       <div className="flex flex-column flex-content-center mt-5"><img src={thankyou}/></div>
       <div className="flex flex-content-center thankyou-text mt-3">
        <span>Thank you!</span>
       </div>
       <div className="flex flex-column flex-item-center mt-2">
        <span>Your request has been</span>
        <span>successfully sent!</span>
       </div>
        <div className='flex flex-content-center mt-3 px-3 mb-2'>
        <Link to={'/Home'} className='btn button col-6'>Back to Homepage</Link>
        </div>
        </>
    )
}
export default ThanYou