import React from 'react';
import Header from './layout/Header';
import { Link } from 'react-router-dom';
import next from '../assets/icon/next2.png';
import TaxCalIcon from '../assets/icon/tax-calculat-icon.png';
function TaxCalculator() {
    return (
        <>
            <Header></Header>
            <div className='flex flex-column flex-item-center mt-4 tax-text'>
                <span>Tax Calculator</span>
            </div>
            <div className='flex flex-column flex-content-center flex-item-center mt-3'>
                <span className='tax-calculator-text'>Knowledge of tax is essential.</span>
                <span className='tax-calculator-text'> Ignorance is costly.</span>
            </div>
            <div className='flex flex-content-center mt-4'>
                <Link to={"/Direct-Tax"} className='tax-btn'>Direct Tax<span className='float-right' ><img src={next} style={{ height: "20px" }} /></span></Link>
            </div>
            <div className='flex flex-content-center mt-4'>
                <Link to={"/Indirect-Tax"} className='tax-btn'>Indirect Tax<span className='float-right' ><img src={next} style={{ height: "20px" }} /></span></Link>
            </div>
            <div className='flex flex-content-center mt-4 px-3'>
                <div className="container">
                    <div className="arrow">
                        <div className="outer"></div>
                        <div className="inner"></div>
                    </div>
                    <div className="message-body">
                        <span className='flex flex-content-center mb-2 tax-do-know'>Do you know ?</span>
                        <p>Income tax is levied on individuals and entities based on their earnings and profits.</p>
                    </div>
                </div>
            </div>
            <div className='flex flex-content-end px-3'><img src={TaxCalIcon} style={{height: "108px"}}/></div>

        </>
    )
}

export default TaxCalculator