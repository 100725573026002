import React,{useState} from 'react'
import Header from './layout/Header';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {Url} from '../components/ApiUrl';
function CategoryDetails() {
    const { id,categoryId } = useParams();
    const [img,setimage]= useState(); 
    const http=axios.create({
        baseURL:Url
    });
    http.post('/getCategoryDetails',{catId:id,categoryId:categoryId}).then(response=>{       
        const result= response.data.res;
        result.map(showResult=>{
            document.getElementById('show-res').innerHTML=showResult.text;
            document.getElementById('res-title').innerHTML=showResult.title;
            setimage(showResult.img);
        })
    });
  return (
   <>
   <Header></Header>
    <hr/>
    <div className='flex flex-column flex-content-center mt-3'>
        <div className='flex flex-content-center text-center'>
        <h2 id="res-title" className='flex text-bold'></h2>        
        </div>
        <div className="flex flex-column flex-content-center px-3 mt-2">
            <img className="d-block w-100" src={process.env.PUBLIC_URL + '/category/'+img} alt="First slide" style={{ height: "160px",borderRadius:"15px" }} />
        </div>
        <div id="show-res" className='mt-2 px-3 text-justify'></div>
    </div>  
   </>
  )
}

export default CategoryDetails