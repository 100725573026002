import React from 'react';
import {Routes, Route,Link} from 'react-router-dom';
import { Url } from '../components/ApiUrl';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useState } from 'react';
import Header from './layout/Header';
import Popup from '../components/Popup';

function ConsultantSchedule() {
    const navigate = useNavigate();
    const [meeting,setMeeting]=useState();
    const [date,setDate]=useState();
    const [time,setTime]=useState();
    const [msg,setMsg]=useState();
    const [buttonPopup,setButtonPopup]= useState();
    const [loader,setLoader]=useState('off');
    const [error,setError]=useState()
    const http= axios.create({
        baseURL:Url,
    });
    const saveScheduleMeeting = () =>{
        const error={
                meeting:'Please select Meeting',
                date:'Date field cannot be blank',
                time:'Time field cannot be blank',
                msg:'Message field cannot be blank ',
        };
        for(let field in error){
            if(!eval(field)){
                setError(error[field]);
                setButtonPopup(true);
                return;
            }
        }
        setLoader('on');
        http.post('/saveMeeting',{meeting:meeting,date:date,time:time,msg:msg}).then(response=>{
            if(response.data.status=='200'){
                navigate("/Thank-You");
                setLoader('off');
               
            }

        }).catch(error=>{

        });

    }
  return (
   <>
   <Header></Header>  
   <Popup trigger={buttonPopup} setTrigger={setButtonPopup}>
   <div className="flex flex-content-center mb-4 mt-4">
        <span id="error-login">{error}</span>
    </div>
   </Popup>
    <div className='flex flex-column flex-item-center mt-4 consultant-text1'>
        <span>Schedule your</span>
        <span>meeting</span>       
    </div>
    <div className='flex flex-column flex-content-center flex-item-center mt-5'>
        <span className='consultant-text-schedule'>Take charge of your future</span>
        <span className='consultant-text-schedule'>Book a meeting to accelerate your business!</span>
    </div>
    
    <div class="flex flex-column flex-item-center mt-4">     
        <lable className='lable-meeting'>Select Meeting</lable>
      <div class="input-group mb-2 input-field">                
        <select className="form-control meeting" onChange={e=>setMeeting(e.target.value)}>
            <option>Select Meeting</option>
            <option value="Interview">Interview</option>
            <option value="Zoom Meeting">Zoom Meeting</option>
        </select>
      </div>
    </div>
    <div class="flex flex-column flex-item-center">     
        <lable className='lable-date'>Select Date</lable>
      <div class="input-group mb-2 input-field">                
        <input type="date" className="form-control date" onChange={e=>setDate(e.target.value)} id="lable-date" placeholder="Select date" />      
      </div>
    </div>
    <div class="flex flex-column flex-item-center">     
        <lable className='lable-time'>Select Time</lable>
      <div class="input-group mb-2 input-field">                
        <input type="time" className="form-control time" onChange={e=>setTime(e.target.value)} id="lable-time" placeholder="Select time"/>      
      </div>
    </div>
   
    <div className='flex flex-content-center mt-3 px-3'>
        <textarea className="meeting-textarea" rows="4" onChange={e=>setMsg(e.target.value)} placeholder='Agenda of meeting...'>
            
        </textarea>
    </div>
    <div className='flex flex-content-center mt-3 px-3 mb-2'>
        {loader == 'off' && <> <button className='btn button col-6' onClick={saveScheduleMeeting}>Schedule</button></>}
        {loader == 'on' &&  <button disabled className="button col" id="sub-btn"><center>Please wait..</center></button>}
       
    </div>
   
   </>
  )
}

export default ConsultantSchedule