import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactDOM from 'react-dom';
import Header from './layout/Header';
import pointlogo from '../assets/icon/point-logo.png';
import dpimg from '../assets/icon/dp-img.png';
import updown from '../assets/icon/up-down.png';
import axios from 'axios';
import { Url } from '../components/ApiUrl';

function Invites() {
    const [points, setPoints] = useState(0);
    const [referrals, setReferrals] = useState([]);
    const [day,setDay]=useState();

    const userId = sessionStorage.getItem('ID');

    useEffect(() => {
        const http = axios.create({
            baseURL: Url,
        });

        http.post('/getPointReferral', { id: userId }).then(response => {
            const result = response.data.res1;
            const result2 = response.data.res2;
            setReferrals(result2);
            result.map(point => {

                setPoints(point.total_points);
            })

            const showInviteFriend = result2.map((res, index) => {
                const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
                  ];
                  const date = new Date(res.date);
                  const today = new Date();
                  const isToday= date.getDate()=== today.getDate();
                  if(isToday){                   
                    setDay(1);
                  }
                    const day = date.getDate();
                    const month = monthNames[date.getMonth()];
                    const displayDate = (day === 1) ? 'Today' : `${day} ${month}`;
               
               
                return (
                    <div key={index} className='flex flex-content-center mt-3 px-4 space-between'>
                        <div className='flex'>
                            <img src={dpimg} style={{ height: "45px" }} alt="dp" />
                            <span className='flex flex-column flex-content-center invite-name-text px-4'>
                                {res.NAME}<br />
                                <span className='point-text'>Registered &#8226;<span className='point-text'>{displayDate}</span></span>
                            </span>
                        </div>
                        <div className='flex flex-column'>
                            <span className='point-text'>Points you earned</span>
                            <span className='points-log'>{res.points}</span>
                        </div>
                    </div>
                );
            });
            // Assuming you have a React component
            const InviteFriendComponent = () => {
                return (
                    <div id='ss'>
                        {showInviteFriend}
                    </div>
                );
            };

            // Render the component into the DOM
            ReactDOM.render(<InviteFriendComponent />, document.getElementById('invite-friend'));

        });
    }, [userId]);

    const inviteReferralUser = () => {
        return referrals.slice(0, 2).map(showReferral => (
            <div className='Recent-invite-box flex flex-item-center space-around' key={showReferral.NAME}>
                <span><img src={dpimg} style={{ height: "45px" }} alt="dp" /></span>
                <span>
                    <span className='invite-name-text'>{showReferral.NAME}</span><br />
                    <span className='points-log'>{showReferral.points}</span><br />
                    <span className='point-text'>Points you earned</span>
                </span>
            </div>
        ));
    };
 
    return (
        <>
            <Header />
            <div className='flex flex-content-center mt-5'>
                <div className='flex flex-content-center invite-box px-4 space-between'>
                    <span className='invite-name-text mt-2'>
                        Earned Points<br /><span className='points-log'>{points}</span>
                    </span>
                    <span><img src={pointlogo} style={{ height: "80px", marginTop: "-14px" }} alt="point logo" /></span>
                </div>
            </div>
            <div className='mt-3 px-4 recent-invite'>
                <span>Recent Invites</span>
            </div>
            <div className='flex flex-content-center mt-3 px-3 space-around'>
                {inviteReferralUser()}
            </div>
            <div className='flex mt-3 px-4 recent-invite space-between'>
                <span>Invited Friends</span>
                <span><img src={updown} style={{ height: "25px" }} alt="up down" /></span>
            </div>
            <div id="invite-friend" className='flex-content-center'></div>
        
        </>
    )
}

export default Invites;
