import React from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate,Link} from "react-router-dom";
import telephone from '../../assets/icon/telephone.png';
import more from '../../assets/icon/more.png';
// import logo from '../../assets/logo/3ea-logo.png';
import logo from '../../assets/logo/newLogo.png';

import arrow from '../../assets/icon/arrow.png';
import HomeImg from '../../assets/icon/home.png';
import LeftMenu from '../../components/LeftMenu';
import '../../components/LeftMenu.css';
import {useState} from 'react';
function Header(props) {   
  let navigate = useNavigate();  
  let location= useLocation(); 
  const phoneNumber= '+91 8303000500'
  const [buttonMenu, setButtonMenu]= useState(false); 
  const showMore = ()=>{   
    setButtonMenu(true);
  }
  
  return (props)?(
    <>
    <LeftMenu trigger={buttonMenu} setTrigger={setButtonMenu}></LeftMenu>
    {/* <div id="push-subscribe-container">
      <div id="push-subscribe-body">    
          <div className="flex">
              <img src={logo} alt="3EA Limited" className="push-logo"/>
              <div className="flex flex-column">
                  <p className='flex px-3'>Receive the latest news, updates and offers as they happen.</p>
                  <div className="flex flex-content-end">                      
                      <button className="btn button col-4 js-push-btn" type="button">Allow</button>
                  </div>
              </div>
          </div>
              <section className="subscription-details js-subscription-details is-invisible">
          </section>
      </div>
    </div> */}
    <div className="flex flex-content-center space-between mt-3 px-2">
        {location.pathname=='/Home'?(
          <span><Link to={`tel:${phoneNumber}`} style={{color:"black"}} id="telephone"><img src={telephone} alt="" style={{height:"25px"}} /></Link></span>
        ):(
          <span onClick={() => navigate(-1)}><Link style={{color:"black"}} id="arrow"><img src={arrow} alt="" style={{height:"25px"}} /></Link></span>
        )}
        <span><img src={logo} alt="" id="logo" style={{width:"140px"}} /></span>
        {location.pathname=='/Consulting'?(
          <span><Link to={"/Home"} id="more" style={{color:"black"}}><img src={HomeImg} alt="" style={{height:"25px"}} onClick={showMore}/></Link></span>
        ):(
          <span><Link style={{color:"black"}} id="more"><img src={more} alt="" style={{height:"25px"}} onClick={showMore}/></Link></span>
        )}
       
      </div>
    </>
  ):"";
}

export default Header