import { Routes,Route,Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Url } from '../components/ApiUrl';
import axios from 'axios';
import logo from '../assets/logo/3ea-logo.png';
import google from '../assets/icon/google.png';
import facebook from '../assets/icon/facebook.png';
import smartphone from '../assets/icon/smartphone-call.png';
import padlock from '../assets/icon/padlock.png';
import hide from '../assets/icon/hide.png';
import view from '../assets/icon/view.png';
import Popup from '../components/Popup';
import '../components/Popup.css'
export default function AuthLogin(){  
  const navigate = useNavigate();
  const [mobile,setMobile] = useState();
  const [password,setPassword] = useState();
  const [loader,setLoader] = useState('off');
  const [buttonPopup,setButtonPopup] = useState(false);
  const [error,setError]= useState();
  const [showpass,setShowpass]= useState(false);
  const http=axios.create({
      baseURL:Url,
  });
  const onLogin = () =>{
    setLoader('on')
    const errors={
            mobile:"Mobile field cannot be blank",
            password:"Password field cannot be blank",
    };
      for(let field in errors){
        if(!eval(field)){
          setError(errors[field])
          setLoader('off')
          setButtonPopup(true);
          return;
        }
      }
    http.post('login',{mobile:mobile,password:password}).then(response=>{
       
      if(response.data.success=='false'){
          setLoader('off')        
          setError(response.data.message)
          setButtonPopup(true);
      }else if(response.data.success=='true'){ 
        sessionStorage.setItem('MOB', response.data.res.MOB);  
        sessionStorage.setItem('ID', response.data.res.id);  
        sessionStorage.setItem('EMAIL', response.data.res.EMAIL);  
        sessionStorage.setItem('REFCODE', response.data.res.ref_code);  
        sessionStorage.setItem('POINTS', response.data.res.POINTS);  
          navigate("/Home");
      }
    }).catch(error=>{

    });
  }
  const toggleMask = () =>{
    setShowpass(true);
  }
  const toggleMask2 = () =>{
    setShowpass(false);
  }
    return(
        <>
        <Popup trigger={buttonPopup} setTrigger={setButtonPopup}>
          <div className="flex flex-content-center mb-4 mt-4">
          <span id="error-login">{error}</span>
          </div>
        </Popup>
        
        <div className='login-top-section'>
          <div className="top-section-inner">
            <div className="flex flex-column flex-item-center">
              <div className="flex flex-column flex-item-center mt-4">
                <img src={logo} alt=""  id="logo" />
              </div>
              <div className="flex flex-column flex-item-center mt-4">

                <span id="login-title">Log in to your Account</span>
                <span id="login-title-sub">Welcome back!</span>
              </div>
                <div className="flex mt-1">
                   <span id="google"> <img src={google} id="fa-icon"/> &nbsp; Google</span>
                   <span id="facebook"> <img src={facebook} id="fa-icon"/>&nbsp; Facebook</span>
                </div>
                <div className="flex flex-column flex-item-center mt-2">
                    <span id="login-title-sub"><sup>______</sup>or continue with mobile no.<sup>_______</sup></span>
                </div>
            </div>
          </div>
        </div>
        <div className="buttom-section">
          <div className="px-5">
          
            <div className="input-group mb-2">
                <div className="input-group-prepend">
                    <div className="input-group-text"><img src={smartphone} id="fa-icon"/></div>
                </div>
                <input type="text" inputMode={'numeric'} onChange={e=>setMobile(e.target.value)} className="form-control" maxLength={10} autoComplete="mobile" placeholder="Mobile Number"/>
            </div>            
            <div className="input-group mb-2">
                <div className="input-group-prepend">
                    <div className="input-group-text"><img src={padlock} id="fa-icon"/></div>
                </div>
                <input type={showpass?"text":"password"} onChange={e=>setPassword(e.target.value)} className="form-control" autoComplete="current-password" placeholder="Enter Password"/>
                <div className="input-group-append">
                    <div className="input-group-text">
                        <a href="#" onClick={showpass?toggleMask2:toggleMask} id="eye"><img src={showpass?view:hide} id="fa-icon"/></a>
                    </div>
                </div>
            </div>
            <div className="mt-2">
              {loader=='off'&& <><button className="button col" type="button" onClick={onLogin}>Login</button></>}
              {loader=='on'&& <><button className="button col">Please wait..</button></>}
                
            </div>
            <div className="mt-2">
                <span id="login-title-sub"><Link to={"/Forgot-Form"}><u>Forgot Password</u></Link></span>
            </div>
            <div className="flex flex-column flex-content-center mt-4">

                <span id="login-title-sub">Don’t have an account? <Link to={"/Signup"}><u>Create an account</u></Link></span>
            </div>
           
          </div>
        </div>
        </>
    );
}